import { useCallback, useState, useEffect } from 'react';
import {
  useLocalizedVariant,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { ColorVariantSelector } from './ColorVariantSelector';
import { Link } from '../Link';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { ProductStars } from '../ProductStars';
import { QuickShop } from './QuickShop';
import { useDataLayerActions } from '../../hooks';

export function ProductItem({
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledQuickShop,
  enabledQuickShopBIS, 
  enabledStarRating,
  enabledProductType,
  handle,
  index,
  isSearchResults,
  isCollection,
  collectionTitle,
  collectionHandle,
  onClick,
  product: passedProduct,
  swatchesMap,
  isCompact = false,
  resetQuickShop = false,
}) {
  const settings = useSettings();
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { product: fetchedFullProduct } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const { sendClickProductItemEvent } = useDataLayerActions();

  const [showQuickShop, setShowQuickShop] = useState(false);

  const initialProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const [productFromColorSelector, setProductFromColorSelector] =
    useState(null);
  const [variantFromColorSelector, setVariantFromColorSelector] =
    useState(null);

  const selectedProduct = productFromColorSelector || initialProduct;
  const selectedVariant =
    variantFromColorSelector || selectedProduct?.variants?.[0];

  const productUrl = selectedProduct
    ? `/products/${selectedProduct.handle}?variant=${selectedVariant.legacyResourceId}`
    : '';
  const color = selectedVariant?.selectedOptionsMap?.Color;
  const title = selectedProduct?.title?.split(' | ')[0];
  const type = selectedProduct?.productType;

  const { localized } = useLocalizedVariant({ variant: selectedVariant });

  const enabledCustomSoldOutQty = settings?.product?.options?.enabledCustomSoldOutQty;
  const soldOutQty = enabledCustomSoldOutQty ? settings?.product?.options?.soldOutQty : 1;

  useEffect(() => {
    if (resetQuickShop) {
      setShowQuickShop(false);
    }
  }, [resetQuickShop]);

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: isSearchResults,
      listIndex: index,
      localized,
      product: selectedProduct,
      selectedVariant,
    });
    if (typeof onClick === 'function') onClick();
    if (isSearchResults || isCollection) {
      sessionStorage.setItem('savedProductId', selectedProduct?.handle);
    }
    if (isCollection) {
      sessionStorage.setItem('savedCollectionTitle', collectionTitle);
      sessionStorage.setItem('savedCollectionHandle', collectionHandle);
    } else {
      sessionStorage.removeItem('savedCollectionTitle');
      sessionStorage.removeItem('savedCollectionHandle');
    }
  }, [index, localized, selectedProduct?.id, selectedVariant?.id]);

  return (
    <>
      <div className="group relative flex h-full flex-col" ref={ref}>
        <div
          className={`flex h-full bg-white ${
            isCompact ? 'flex-row' : 'flex-col'
          }`}
        >
          <div className={`relative ${isCompact ? 'w-[35%]' : 'w-full'}`}>
            <Link
              aria-label={title}
              className="w-full"
              href={productUrl}
              onClick={handleClick}
              tabIndex="-1"
            >
              <ProductItemMedia
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
                isCompact={isCompact}
                soldOutQty={soldOutQty}
                isGiftCard={selectedProduct?.isGiftCard}
              />
            </Link>
            {enabledQuickShop && !isCompact && (
              <QuickShop
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
                soldOutQty={soldOutQty}
                enabledQuickShopBIS={enabledQuickShopBIS}
              />
            )}
          </div>

          <div
            className={`flex flex-col p-2.5 !pb-0 ${
              isCompact ? 'w-[65%] gap-[5px]' : 'h-full w-full gap-2.5 md:p-5'
            }`}
          >
            {enabledStarRating && initialProduct?.legacyResourceId && (
              <ProductStars 
                legacyResourceId={initialProduct?.legacyResourceId}
                isCompact={isCompact}
              />
            )}
            <div className="flex flex-col">
              <Link aria-label={title} href={productUrl} onClick={handleClick}>
                <h3
                  className={`min-h-[1.5rem] ${
                    isCompact
                      ? 'truncate text-base font-normal'
                      : 'text-body-lg text-bold '
                  }`}
                >
                  {title}
                </h3>
              </Link>

              {!isCompact && (enabledProductType || color) && (
                <p className="text-base text-mediumGray">
                  {enabledProductType && `${type}${color ? ' | ' : ''}`}
                  {/* {color && `${color}`} */}
                  {color && `${color.split('|').length > 1 ? color.split('|')[1].trim() : color}`}
                </p>
              )}

              <ProductItemPrice
                selectedVariant={selectedVariant}
                isCompact={isCompact}
              />
            </div>

            {enabledColorSelector && (
              <ColorVariantSelector
                enabledColorNameOnHover={enabledColorNameOnHover}
                initialProduct={initialProduct}
                selectedVariant={selectedVariant}
                setProductFromColorSelector={setProductFromColorSelector}
                setVariantFromColorSelector={setVariantFromColorSelector}
                swatchesMap={swatchesMap}
              />
            )}

            {enabledQuickShop && !isCollection && (
              <QuickShop
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
                isCompact={isCompact}
                isTrigger
                setShowQuickShop={setShowQuickShop}
                showQuickShop={showQuickShop}
                soldOutQty={soldOutQty}
                enabledQuickShopBIS={enabledQuickShopBIS}
              />
            )}
          </div>
        </div>
      </div>
      <div className={`relative mt-2.5 ${showQuickShop ? 'block' : 'hidden'}`}>
        <QuickShop
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
          isCompact
          soldOutQty={soldOutQty}
          enabledQuickShopBIS={enabledQuickShopBIS}
        />
      </div>
    </>
  );
}

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  quickShopBIS: PropTypes.object,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchResults: PropTypes.bool,
  isCollection: PropTypes.bool,
  collectionTitle: PropTypes.string,
  collectionHandle: PropTypes.string,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
