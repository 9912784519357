import { ReviewStars } from '../ReviewStars';

const decodeHtmlEntities = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.documentElement.textContent;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().substr(-2);
  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
};

export function ProductReviewsReview({
  review,
}) {

  const customFields = review.custom_fields || {};
  const customFieldFitId = '--95085';
  let customFieldFitTitle = '';
  let customFieldFitValue = '';
  let sizeMapping = {
    1: 'Small',
    2: 'True to size',
    3: 'Big'
  };
  if (customFields && customFields[customFieldFitId] && customFields[customFieldFitId].title) {
    customFieldFitTitle = customFields[customFieldFitId].title;
    customFieldFitValue = customFields[customFieldFitId].value;
  }
  const size = sizeMapping[customFieldFitValue];

  return (
    <div className="h-full flex flex-col py-5 first:pt-0 last:shadow-none">
      <div 
        className="relative !h-auto"
      >
        <ReviewStars rating={review?.score} size="small" />
        <p className="text-body-lg font-medium mt-2.5">{decodeHtmlEntities(review?.title)}</p>
        <p>{decodeHtmlEntities(review.content)}</p>
        {customFieldFitTitle && <p className="text-body-sm">{customFieldFitTitle}: <span className="font-medium">{size}</span></p>}
      </div>
      <div className="mt-auto">
        <p className="font-medium truncate mt-2.5">{decodeHtmlEntities(review.user?.display_name)}{review.verified_buyer && <span className="text-xs font-normal"> Verified Buyer</span>}</p>
        <p className="text-body-sm">{formatDate(review.created_at)}</p>
        {/* uncomment the following line to show the product name for the Review. Firs you need to uncomment code from ProductReviews */}
        {/* {review.product_name && <p className="text-xs">Reviewed on: {review.product_name}</p>} */}
      </div>
      {review?.comment?.content && (
        <div className="pl-5">
          <div className="border-l-[0.5px] border-gray pl-2.5 text-body-sm text-mediumDarkGray">
            <p className="font-medium truncate mt-2.5">Response from Rhoback<span className="text-xs font-normal"> - {formatDate(review.comment.created_at)}</span></p>
            <p>{decodeHtmlEntities(review.comment.content)}</p>
          </div>
        </div>
      )}
    </div>
  )
}

ProductReviewsReview.displayName = 'ProductReviewsReview';